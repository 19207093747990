import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Button } from 'react-bootstrap';

import './index.scss';

const TOGIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page tog'} game="tog">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Tower of God: New World wiki and tier list </h1>
        <h2>
          Prydwen.gg is a wiki for Tower of God: New World. Check our guides,
          tier lists and reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Important Notice" />
      <p>
        Our Tower of God wiki <strong>will be removed soon</strong>. Sadly, the
        direction the game has taken (leaning too much toward Pay 2 Win) and the
        declining popularity of the game, forced us to make that decision. If
        you're looking for up to date information about the game, check{' '}
        <a
          href="https://www.youtube.com/@Conowen96"
          target="_blank"
          rel="noreferrer"
        >
          Conowen's
        </a>{' '}
        YouTube channel and the sheet he maintains (linked below).
      </p>
      <Button
        variant="primary"
        href="https://docs.google.com/spreadsheets/u/0/d/1paOxiwoKOzzR0E5W_r1SYLVuliJomnz8BkNQt8vNhQU/htmlview#"
        target="_blank"
        rel="noreferrer"
      >
        Conowen's ToG Sheet
      </Button>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default TOGIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Tower of God: New World Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Tower of God: New World. Check our guides, tier lists and reviews for characters available in the game."
    game="tog"
  />
);
